<template>
  <div class="templates-container">
    <div class="content">
      <div class="filters">
        <div class="name">{{ translate("templates.title") }}</div>
        <div class="add-template">
            <span v-tooltip.top-start="isAdmin === false ? 'You do not have access. Please contact the WBS App administrator': ''">
            <app-button :class="{'disabled': isAdmin === false}" :color="'primary'" type="button" @click="addNewTemplate">{{
                    translate("templates.add_template")
                }}</app-button>
          </span>
        </div>
        <div class="filters-field filters-search">
          <label>{{ translate("search") }}</label>
          <app-search-input-big v-model="searchText" :placeholder="translate('search')" @input="search" />
        </div>
        <div class="filters-field filters-status">
          <label>{{ translate("templates.status") }}</label>
          <base-select-box v-model="statusId" :options="statusList" :placeholder="translate('templates.select_status')"
            :selectedText="translate('selected')" label-by="label" value-by="value" :close-on-select="true"
            :small-font="true" :min="1" @change="filterStatus" />
        </div>
        <div class="pagination">
          <div class="count-on-page">
            <app-count-on-page v-model="countOnPage" @change="onCountPageChange" />
          </div>
          <app-pagination v-if="pages" v-model="page" :pages="pages" :range-size="1" :hideFirstButton="true"
            :hideLastButton="true" @update:modelValue="onChangePage" />
        </div>
      </div>
      <app-templates-table :header="columns" :orderBy="orderBy" @onChangeOrder="changeOrder">
        <template v-slot:row>
          <app-template-row :row="template" :key="template.id + 'data'" v-for="template in templates"
            @onEdit="onEditRow" @onRemove="onRemoveRow">
            <icon-bag v-if="isAdmin" v-tooltip="translate('create_project')" :fill="'#0f8af9'" @click="createProject(template)"
              :width="'30px'" />
            <icon-next-round v-tooltip="translate('open_template')" @click="openTemplate(template)" :width="'30px'" />
          </app-template-row>
        </template>
      </app-templates-table>
      <p class="no-found" v-if="isNotFound">
        {{ translate("search_not_found") }}
      </p>
    </div>
  </div>
</template>
<script>
import { computed, inject, onMounted, reactive, toRefs, ref, watch } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import debounce from "lodash.debounce";
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button.vue";
import AppTemplatesTable from "@/components/table/TemplatesTableList.vue";
import AppTemplateRow from "@/components/table/rows/TemplateRowList.vue";
import AppPagination from "@/components/shared/Pagination.vue";
import AppCountOnPage from "@/components/shared/CountOnPage.vue";
import BaseSelectBox from "vue-select-box";
import IconNextRound from "@/components/icons/IconNextRound.vue";
import IconBag from "@/components/icons/IconBag";
import { useCookies } from "@/helpers/cookies";
import { useKeyboard } from "@/helpers/keyboard";
import { useLocation } from "@/helpers/location";
import AppSearchInputBig from "@/components/shared/SearchInputBig.vue";
export default {
  components: {
    AppButton,
    AppTemplatesTable,
    AppTemplateRow,
    AppPagination,
    BaseSelectBox,
    IconNextRound,
    IconBag,
    AppCountOnPage,
    AppSearchInputBig,
  },
  inject: ["translate"],
  setup() {
    const translate = inject("translate");
    const store = useStore(),
      { readCookie } = useCookies(),
      notSortColumn = ["user_access"],
      state = reactive({
        searchText: "",
        countOnPage: 10,
        meta: computed(() => store.getters["templates/meta"]),
        pages: computed(() => Math.ceil(state.meta.total / state.countOnPage)),
        wasRequest: false,
        isNotFound: computed(
          () => state.wasRequest && state.searchText && !state.templates.length
        ),
        page: 1,
        columns: computed(() => {
          return ["name", "user_access", "nodes", "updatedAt", "status"].map(
            (c) => {
              return {
                name: c,
                title: translate(`templates.${c}`),
                isSort: notSortColumn.includes(c) ? false : true,
              };
            }
          );
        }),
        orderBy: {
          column: "updatedAt",
          value: "asc",
        },
        templates: computed(() => store.state.templates.data || []),
        statusId: 1,
        statusList: computed(() => store.getters["templates/filterStatusList"]),
      }),
        isAdmin = computed(() => store.getters["profile/isAdmin"]);
    const { isCtrlDown } = useKeyboard();
    const filterString = ref(null);

    const getTemplates = async () => {
      if (
        state.templates.length == 1 &&
        state.pages > 1 &&
        state.page == state.pages
      ) {
        state.page--;
      }
      await store.dispatch("templates/getTemplates", {
        phrase: state.searchText,
        _page: state.page,
        _filter: filterString.value,
        _sort: state.orderBy
          ? state.orderBy.column + "&_order=" + state.orderBy.value
          : "",
        _limit: state.countOnPage,
      });
    };

    const debSearch = debounce(async () => {
      await getTemplates();
      state.wasRequest = true;
    }, 500);

    const search = () => {
      state.page = 1;
      state.wasRequest = false;
      debSearch();
    };

    const onChangePage = async (_page) => {
      if (!_page) return;
      state.page = _page;
      getTemplates();
    };

    const { openModal, storeModal } = useModalHelper();

    const addNewTemplate = () => {
        if (isAdmin.value === false) return;

      openModal("NewTemplate");
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          if (data.payload.id) {
            router.push({
              name: "wbs-template-list-view",
              params: { id: data.payload.id },
            });
          } else {
            getTemplates();
          }
        }
        unsubscribe();
      });
    };

    const onEditRow = (row) => {
        if (isAdmin.value === false) return;

      openModal("NewTemplate", {
        title: row.name || "",
        template: row,
      });

      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          await getTemplates();
        }
        unsubscribe();
      });
    };

    const onRemoveRow = (id) => {
        if (isAdmin.value === false) return;

      openModal("Confirmation", {
        title: computed(() => translate("modal.confirmation")),
        text: computed(() => translate("modal.remove_template")),
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          await store.dispatch("templates/removeTemplate", id);
          getTemplates();
        }
        unsubscribe();
      });
    };

    const filterStatus = (e) => {
      const value = state.statusList.find((s) => s.value == e);
      if (e == 0 || !e) {
        filterString.value = "";
      } else if (value) {
        filterString.value = `&status=${value.label}`;
      }

      getTemplates();
    };

    const changeOrder = (e) => {
      state.orderBy = e;
      getTemplates();
    };
    const openTemplate = async (row) => {
      const viewStyle = await readCookie(`view-style-${parseInt(row.id)}`);
      if (isCtrlDown.value) {
        if (!config.isLocal) {
          const url = await useLocation().getUrl(row.id, "templates");
          window.open(url, "_blank");
          return;
        } else {
          const url = `/wbs-template/${row.id}`;
          window.open(url, "_blank");
        }
        return;
      }
      router.push({ name: "wbs-template-list-view", params: { id: row.id } });
    };
    const createProject = (row) => {
        if (isAdmin.value === false) return;

      openModal("NewProject", {
        template: row,
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          if (data.payload.id) {
            router.push({
              name: "wbs-project-list-view",
              params: { id: data.payload.id },
            });
          }
          unsubscribe();
        }
        if (data.type === "modal/closeModal") {
          unsubscribe();
        }
      });
    };

    const onCountPageChange = (value) => {
      const { saveCookie } = useCookies();
      saveCookie(`count-on-page-template`, value);
      getTemplates();
    }

    onMounted(async () => {
      const { readCookie } = useCookies();
      const count = await readCookie(`count-on-page-template`);
      state.countOnPage = count ? parseInt(count) : 10;

      filterStatus(1);
    });

    watch(()=>router.currentRoute.value.params.openModal, (value)=>{
      if (value=='true') {
        addNewTemplate();
      }
    }, {
      immediate: true,
    })
    return {
      ...toRefs(state),
      onChangePage,
      search,
      addNewTemplate,
      onRemoveRow,
      onEditRow,
      filterStatus,
      changeOrder,
      openTemplate,
      createProject,
      onCountPageChange,
        isAdmin
    };
  },
};
</script>
<style lang="scss" scoped>
.templates-container {
  height: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    "Content";
  .table-container {
    margin: 0 -25px;
  }
  .buttons {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 150px;

    .button {
      height: 40px;
      grid-area: 1 / 2;
    }
  }

  .header {
    grid-area: Header;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 300px;

    .header-title {
      grid-area: 1 / 1 / 1 / 1;
    }

    .search-control {
      grid-area: 1 / 2 / 1 / 2;
    }

    @media (max-width: 1400px) {
      .header-title {
        ::v-deep(.app-name) {
          font-size: 12px;
          line-height: 125%;
        }
      }
    }
  }

  .content {
    grid-area: Content;
    background: #fff;
    padding: 20px 25px;
    border-radius: 8px;
    position: relative;
    grid-template-rows: minmax(100px, 1fr) 1fr;
    height: calc(100vh - 45px);

    .go-to-welcome {
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
    }

    .name {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #363636;

      @media (max-width: 1400px) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    .filters {
      display: grid;
      grid-template-columns: 150px 220px 220px 1fr;
      grid-template-areas:
        "Name . . . . Pagination"
        "AddProject Search Status . . .";
      grid-column-gap: 15px;
      align-items: center;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media (max-width: 1400px) {}

      label {
        font-size: 12px;
        line-height: 18px;
        color: #a1a9ba;
        margin-bottom: 5px;
      }

      .name {
        grid-area: Name;
      }
      ::v-deep(.vue-select-header) {
        height: 45px;
      }
      .add-template {
        grid-area: AddProject;
        align-self: end;

        ::v-deep(button) {
          height: 45px;

          @media (max-width: 1400px) {
            height: 45px;
          }
        }
      }

      .filters-search {
        grid-area: Search;
      }

      .filters-status {
        grid-area: Status;
      }

      .pagination {
        grid-area: Pagination;
        display: grid;
        grid-template-columns: 80px 1fr;
        align-items: center;
        gap: 10px;
        ::v-deep(.vue-select-header) {
          height: 35px;
        }
      }
    }
  }

  .no-found {
    text-align: center;
  }
}
</style>
